import { SafetyCodeContext } from '@canalplus/types-acm';
import { MyCanalTranslations } from './types';

const LOCALE_IT_IT: MyCanalTranslations = {
  A11y: {
    disableAnimationsAutoplay: 'Disattivare le animazioni',
    enableAnimationsAutoplay: 'Attivare le animazioni',
    lastCategory: "Continua a navigare per esplorare l'ultima categoria",
    nextCategory: 'Categoria successiva',
    nextCategoryAriaLabel:
      'Ci si trova nella categoria "%{category}". Per passare alla categoria successiva, fare clic su Invio.',
    skipToMainContent: 'Vai al contenuto principale',
    skipToMenu: 'Vai al menù',
    skipToSearch: 'Vai alla ricerca',
    headerLogo: '%{appName} - homepage',
    navigationMenu: 'Menu di navigazione principale',
  },
  ActionBar: {
    twitterShare: 'Guarda %{movie} su #channel',
    twitterShareSite: 'TIMVISION',
  },
  Alert: {
    attention: 'Attenzione',
    error: 'Errore',
    information: 'Informazione',
    success: 'Successo',
    ignore: 'Ignorare',
  },
  AudioLanguage: {
    Icon: {
      vf: {
        title: 'VI',
        description: 'Versione italiana',
      },
      vost_vf: {
        title: 'VOST/VI',
        description: 'Versione originale con sottotitoli in francese',
      },
      vost: {
        title: 'Lingua originale',
        description: 'Versione originale con sottotitoli',
      },
    },
  },
  Authentication: {
    login: 'Accedi',
    logout: 'Esci',
  },
  AvailabilityLabel: {
    until: 'Fino al',
    promotion: 'Promo',
    minimumPrice: 'A partire da',
    free: 'Grautito',
  },
  Card: {
    isLastDays: 'In scadenza',
    withSeason: 'Con la stagione',
    notInterested: "Non m'interessa",
    removeFromList: "Rimuovi dall'elenco",
    season: 'Stagione',
    subscribeLabel: 'Abbonati',
  },
  Common: {
    validate: 'Conferma',
    modify: 'Modifica',
    delete: 'Elimina',
    yes: 'Sì',
    no: 'No',
  },
  Contact: {
    title: 'Verifica',
    subtitle: 'Scegli come ricevere il codice di verifica',
    type: {
      EMAIL: 'Email',
      PHONE: 'SMS',
    },
  },
  ParentalRating: {
    rating1: 'Tutto pubblico',
    rating2: 'Sconsigliato ai minori di 6 anni',
    rating3: 'Sconsigliato ai minori di 12 anni',
    rating4: 'Sconsigliato ai minori di 15 anni',
    rating5: 'Sconsigliato ai minori di 18 anni',
    rating6: 'Per adulti',
  },
  Accessibility: {
    closedCaptioning: 'Sottotitoli per non udenti',
    audioDescription: 'Audiodescrizione',
  },
  Date: {
    at: 'alle',
    timeSeparator: 'ore',
    broadcastToday: 'oggi alle %{hours}:%{minutes}',
    broadcastTodayLabel: '%{hours}:%{minutes}',
    broadcastTomorrow: 'domani alle %{hours}:%{minutes}',
    broadcastTomorrowLabel: 'Domani %{hours}:%{minutes}',
    broadcastYesterday: 'ieri alle %{hours}:%{minutes}',
    broadcastFuture: '%{date} alle %{hours}:%{minutes}',
    broadcastFutureLabel: '%{date} %{hours}:%{minutes}',
    TVODduration: 'Hai fino al %{date} per usufruire di questo programma',
    availabilityText: 'Disponibili fino al {date}',
  },
  DaySelector: {
    error: 'Si è verificato un errore. Ti invitiamo a riprovare più tardi.',
  },
  DeleteAccount: {
    title: 'Supprimer mon compte',
  },
  Detail: {
    season: 'Stagione',
  },
  DownloadToGo: {
    available:
      'Dopo aver acquistato o noleggiato questo contenuto, potrai approfittare del download. Questa funzione ti consente di guardare i tuoi contenuti offline e ti garantisce una visione di buona qualità a basse velocità',
    downloads: 'Download',
    noContent: 'Nessun contenuto scaricato.',
    notAvailable: 'Su richiesta del produttore, questo contenuto non è disponibile per il download',
    sizeConsumption: '%{used} utilizzato su %{total}',
  },
  DownloadToGoConfirmationDeletionModal: {
    cancelButton: 'Annulla',
    deleteButton: 'Ok',
    deleteText: 'Vuoi eliminare %{title}?',
    deletionTitle: 'Eliminazione in corso',
    title: 'Conferma eliminazione',
  },
  DownloadToGoDownloadModal: {
    deleteButton: 'Elimina',
    pauseDownloadButton: 'Pausa',
    pauseText: 'In attesa - %{percentage} %',
    progressionText: 'In corso - %{percentage} %',
    restartDownloadButton: 'Riprendi',
  },
  DownloadToGoInformationModal: {
    availabilityText: 'Disponibile fino al %{date}',
    deleteButton: 'Elimina',
    informationButton: 'Informazioni',
    playButton: 'Riproduci',
  },
  DownloadToGoModal: {
    title: 'È necessario prima installare il modulo di download CANAL+',
    step1Title: 'Scarica',
    step1Text: 'Salva il file di installazione sul tuo computer',
    step2Title: 'Installa',
    step2Text: 'Fare doppio clic sul file di installazione',
    step3Title: 'Guarda',
    step3Text: 'Goditi i tuoi programmi scaricati, anche offline',
    optionText: 'Se il download non si avvia anche se il modulo è installato correttamente,',
    optionBtn: 'fai clic qui',
  },
  DownloadToGoQualityModal: {
    title: 'Impostazioni',
    description:
      'Seleziona la qualità dei tuoi file scaricati. Per modificarla, puoi farlo nella sezione impostazioni in My Account.',
    lowBtn: 'Download rapido',
    highBtn: 'Qualità elevata',
    cancelBtn: 'Annulla',
  },
  DownloadToGoMacOsModal: {
    title: "Installare l'app TIMvision per scaricare questo contenuto	",
    appNotAvailable:
      "Guarda i tuoi contenuti offline sull'applicazione TIMvision disponibile su MacOS, iOS, Android e Windows",
    downloadAppInfos:
      "Per scaricare i tuoi contenuti, ti invitiamo a utilizzare l'app myCANAL per macOS. Applicazione disponibile sul Mac App Store a partire dalla versione macOS Catalina 10.15.4",
    upgradeOs: "È necessario aggiornare la versione del sistema operativo per utilizzare l'app macOS myCANAL",
    closeBtn: 'Chiudi',
    downloadAppBtn: "Accedi all'App Store",
    appStoreAlt: 'Scarica su App Store',
    microsoftStoreAlt: 'Scarica per Microsoft',
    playStoreAlt: 'Disponibile su Google Play',
  },
  EpisodesContainer: {
    switchEpisodesLabel: 'Trama episodio',
    seeMoreButton: 'Leggi tutto',
  },
  ErrorMessages: {
    technicalIssue: 'Abbiamo riscontrato un problema tecnico. Ti invitiamo a riprovare più tardi',
    orderMessage: 'Si è verificato un problema con il tuo ordine',
    saleOrderMessage:
      'Si è verificato un problema tecnico. Il tuo ordine non è stato registrato, ti invitiamo a rieffettuarlo o a riprovare più tardi.',
    mandatoryField: 'Il tuo ordine non è stato registrato. I campi relativi a città, CAP e indirizzo sono obbligatori.',
    invalidCivility: 'Il tuo ordine non è stato registrato. Il campo stato civile non è valido',
    hibernateWrite:
      "Il tuo ordine non è stato registrato. L'abbinamento città e codice postale non è presente nel nostro archivio indirizzi.",
    noPaymentMeansInfo:
      "Abbiamo riscontrato un'anomalia con il tuo profilo Regolarizza la tua situazione contattando il servizio clienti.",
    noPaymentMeans: 'Non hai registrato alcun metodo di pagamento. Vai su',
    unpaid: "Abbiamo riscontrato un'anomalia con il tuo profilo Vai su",
    thirdParty: 'Il tuo profilo è un pagatore di terza parte. Vai su',
    warnBizBlockingThirdPayers: `Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    warnBizBlockingCaped: `Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    warnBizBlockingUnpaid: `Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    warnBizNoBill: `Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    errSystemError: `Si è verificato un problema, ti invitiamo a riprovare più tardi. Se il problema persiste contatta l'Assistenza Clienti TIM.`,
    errTokenIdentity: `Si è verificato un problema momentaneo. Vai al tuo profilo e aggiorna i "diritti di visione", oppure riavvia l'app. Se il problema persiste contatta il Servizio Clienti TIM.`,
    errTokenIdentityPayment: `Si è verificato un problema momentaneo. Vai al tuo profilo e aggiorna i "diritti di visione", oppure riavvia l'app. Se il problema persiste contatta il Servizio Clienti TIM`,
    errOrderPaid: `Contenuto non acquistabile oppure già acquistato. Verifica se presente nella sezione "I Miei Contenuti", altrimenti contatta l'Assistenza Clienti TIM.`,
    warnBizNoAlternatePaymentMode: `Il pagamento dall'app per il momento non è disponibile. Visita timvision.it`,
    errNoSubscriber: `Si è verificato un problema momentaneo, ti invitiamo a verificare i tuoi metodi di pagamento nel tuo account e riprovare più tardi.`,
    errBizBillConfirmation: `Il tuo ordine non è andato a buon fine. Ti preghiamo di riprovare.`,
    errBizBlockingVod: `Il tuo ordine non è andato a buon fine. Prima di continuare l'operazione contatta il Servizio Clienti TIM.`,
    errOrderPaidPayment: `Contenuto non acquistabile oppure già acquistato. Verifica se presente nella sezione "I Miei Contenuti", altrimenti contatta l'Assistenza Clienti TIM.`,
    errSystemErrorPayment: `Si è verificato un problema, ti invitiamo a riprovare più tardi. Se il problema persiste contatta l'Assistenza Clienti TIM.`,
    errBizBlockingCaped: `Abbiamo riscontrato un problema con il tuo metodo di pagamento. Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    errBizBlockingNoBill: `Abbiamo riscontrato un problema con il tuo metodo di pagamento. Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    errInconsistentOrder: `Il tuo ordine non è andato a buon fine. Prima di continuare l'operazione contatta il Servizio Clienti TIM.`,
    errOrderNotFound: `Il tuo ordine non è andato a buon fine. Ti preghiamo di riprovare.`,
    errJwtToken: `A causa di un problema tecnico, il pagamento non è andato a buon fine ed il tuo ordine non è stato completato. Ti preghiamo di riprovare.`,
    warnBizBlockingUnpaidPayment: `Abbiamo riscontrato un problema con il tuo metodo di pagamento. Prima di continuare l'operazione vai al tuo account e verifica i dati su "strumenti di pagamento".`,
    errBillError: `Il tuo ordine non è andato a buon fine. Ti preghiamo di riprovare.`,
    errStopVod: `Hai richiesto il blocco degli acquisti dei contenuti On Demand. Contatta il Servizio Clienti TIM per accedere al servizio.`,
    wrongCB:
      "I dati inseriti risultano errati. Inserisci nuovamente il tuo metodo di pagamento o seleziona un'altra opzione di pagamento",
    wrongCBData: 'Il numero della carta risulta errato. Ti invitiamo a riprovare',
    wrongCBValidation: "Errore nell'inserimento dei dati della carta di credito",
    paymentMessageSecondPart: 'per ordinare il tuo programma utilizzando una carta di credito.',
    genericErrorTitle: 'Abbiamo riscontrato un problema',
    genericErrorTxt: 'I nostri tecnici sono al lavoro per risolverlo',
    invalidAddress: "Errore nell'inserimento dell'indirizzo",
    noContent: 'Non è disponibile alcun contenuto in questo momento.',
    noVod: 'I noleggi e gli acquisti non sono autorizzati per questo account cliente.',
    retry: 'Riprova',
  },
  ErrorTemplate: {
    backToHome: 'Torna alla home page',
    moreInfo: 'Più informazioni',
  },
  ExternalServiceModal: {
    title: 'Attivazione',
    streamInfos:
      "Devi attivare il tuo account %{channelName} per iniziare la riproduzione di questo contenuto sull'app %{channelName}.",
    downloadInfos:
      "Devi attivare il tuo account %{channelName} per scaricare questo contenuto sull'app %{channelName}.",
    btn: 'Attiva il mio account %{channelName}',
    partnerActivation: {
      title: 'Attivazione',
      streamInfos: `È necessario attivare il proprio account %{channelName} per poter giocare a questo contenuto sull'app %{channelName}. Vi è stata inviata un'e-mail da CANAL+ con un link di attivazione. Potete anche accedere alla sezione "Abbonamento" del vostro Espace Client web CANAL+ per effettuare l'attivazione.`,
      downloadInfos: `È necessario attivare il proprio account %{channelName} per poter scaricare a questo contenuto sull'app %{channelName}. Vi è stata inviata un'e-mail da CANAL+ con un link di attivazione. Potete anche accedere alla sezione "Abbonamento" del vostro Espace Client web CANAL+ per effettuare l'attivazione.`,
      closeBtn: 'Chiudi',
    },
  },
  FieldErrorMessage: {
    cgu: 'Devi accettare le Condizioni generali di vendita per continuare',
    email_login: 'Controlla il formato della tua email',
    email_phone_login: 'Controlla il formato della tua email o del tuo telefono',
    msd_number: 'Controlla il numero della tua carta o il tuo decoder',
    num_abo: 'Ti invitiamo a controllare il paese di abbonamento e il numero di rinnovo',
    new_password: 'La tua nuova password deve essere diversa da quelle precedenti',
    new_password_too_recent: 'La tua nuova password è stata utilizzata troppo di recente',
    old_password: 'Inserisci la password corrente',
    password: 'Verifica la tua password',
    phone_email_login: 'Controlla il formato della tua email o del tuo telefono',
    phone_login: 'Controlla il formato del tuo telefono',
    resubscription_number: 'Ti invitiamo a controllare il paese di abbonamento e il numero di rinnovo',
    subscription_number: 'Ti invitiamo a controllare il paese di abbonamento e il numero di rinnovo',
    zip_code: 'Il tuo codice postale deve essere composto da 5 cifre',
  },
  FunnelTvod: {
    addPromotionalCode: 'Aggiungi il codice promozionale',
    agree: 'Visiona le',
    agreeTV:
      'Visiona le Condizioni Generali per la fruizione di contenuti della piattaforma TIMVISION a noleggio e in acquisto.',
    CBDisabledAlert:
      'Per concludere l\'acquisto con carta di credito, accedere al sito myCANAL. Troverete poi tutti i vostri acquisti e noleggi nella sezione "I miei video".',
    CBDisabledTitle: 'Pagamento con carta di credito non disponibile in TV',
    cgvAlert: '',
    cgvLabel: 'Condizioni Generali',
    cgvName: 'Condizioni Generali',
    close: 'Chiudi',
    deleteCBModal: {
      cancelBtn: 'Annulla',
      confirmBtn: 'Confermare',
      label: 'Siete sicuri di voler cancellare il record del numero di carta bancaria %{cardNumber} ?',
      subLabel: "(la cancellazione non avrà alcun effetto sull'abbonamento)",
    },
    deleteCBRegistered: 'Eliminazione di una carta bancaria registrata',
    deleteCBRegisteredBtn: 'Elimina',
    insteadOf: 'invece di',
    libCB1Shot: 'Pagamento con carta di credito',
    promotionalCode: 'Codice promozionale',
    reload: 'Aggiorna',
    terms: 'Condizioni Generali per la fruizione di contenuti della piattaforma TIMVISION a noleggio e in acquisto.',
    validate: 'Conferma',
    withdrawal: "Prendo atto che l'acquisto del contenuto non prevede applicazione del diritto di recesso",
    withdrawalAlert: 'Per accedere al servizio, è necessario rinunciare al diritto di recesso',
    withdrawalName: 'Diritto di recesso',
  },
  PrivacyManager: {
    acceptAll: 'Accetta tutto',
    adsTitle: 'Pubblicità personalizzata',
    anonymous: 'Anonimo',
    authenticatedCase:
      'La vostra scelta sarà valida su tutti i supporti digitali del Groupe Canal+ collegati al vostro account CANAL+.',
    confidentialityCGU: 'Privacy e Condizioni generali',
    refuseAll: 'Rifiuta tutto',
    disabled: 'Spento',
    enabled: 'Attivato',
    generalInfo:
      "GROUPE CANAL+ attribuisce la massima importanza alla gestione dei vostri dati personali e vi consente di controllarne l'utilizzo attraverso le impostazioni riportate di seguito. \nI vostri dati vengono raccolti per garantire il funzionamento ottimale della vostra applicazione e per personalizzare la vostra esperienza o le vostre pubblicità.",
    modal: {
      goBack: 'Indietro',
      saveAndClose: 'Salva e chiudi',
      title: 'Vuoi lasciare questa pagina?',
    },
    performanceTitle: 'Analisi di prestazione',
    required: 'Obbligatorio',
    saveAndClose: 'Salva e chiudi',
    serviceTitle: 'Funzionamento del servizio',
    title: 'Informazioni sui tuoi dati',
  },
  GDPRConsent: {
    title: 'Informazioni sui tuoi dati',
    generalInfo:
      'Groupe CANAL+ e i suoi affiliati (membri del gruppo Vivendi) e partner utilizzano traccianti per gestire e migliorare i nostri servizi e offrirti pubblicità più pertinenti.',
    updateChoices:
      'Puoi configurare le tue scelte per finalità e aggiornarle in qualsiasi momento tramite la nostra sezione Privacy.',
    authenticatedCase:
      "Se hai effettuato l'accesso con il tuo account CANAL+, la scelta sarà valida per l'utilizzo e la navigazione della home su tutte le applicazioni e media digitali del gruppo Canal+ (elenco dei media).",
    unloggedCase:
      'La tua scelta sarà valida solo sul supporto digitale attualmente utilizzato. Se vi connettete al vostro account CANAL+, le vostre scelte espresse in modalità connessa prevarranno.',
    learnMore: 'Maggiori informazioni e configurazione delle mie scelte',
    serviceTitle: 'Funzionamento del servizio',
    serviceText:
      "Questi identificatori rendono possibile il funzionamento del servizio, consentendo l'autenticazione dell'utente, la sicurezza e la supervisione tecnica (anonima) del servizio. Non è possibile disattivare questa impostazione.",
    performanceTitle: 'Analisi di prestazione',
    performanceText:
      'Questi identificatori vengono utilizzati per misurare l’uso e le prestazioni dei nostri servizi e per adattarli meglio alle vostre esigenze. Quando viene selezionata la modalità "Anonimo", questi identificatori ci permettono di misurare l’uso e le prestazioni dei nostri servizi in modo completamente anonimo, senza tracciamento.',
    adsTitle: 'Pubblicità personalizzata',
    adsText:
      "Questi identificatori ci permettono, insieme ai nostri partner, di offrirvi pubblicità in base ai vostri interessi. In particolare, saremo in grado di: adattare i contenuti pubblicitari in base alla navigazione del vostro terminale o ai dati personali da voi forniti, in particolare utilizzando funzioni di targeting (retargeting) per presentarvi annunci pubblicitari o contatti e-mail in seguito al vostro utilizzo dei nostri servizi; seguire la navigazione successiva effettuata dal vostro terminale; adattare la presentazione del servizio a cui conduce un contenuto pubblicitario; limitare il numero di volte in cui vedete un annuncio; calcolare gli importi dovuti agli attori della catena di distribuzione pubblicitaria; misurare lefficacia e l'impatto di una campagna pubblicitaria. Disattivando questi identificatori, l'utente non sarà esposto a un numero minore di pubblicità, ma a pubblicità che non corrispondono necessariamente ai suoi interessi.",
    enabled: 'Attivato',
    anonymous: 'Anonimo',
    disabled: 'Disattivato',
    required: 'Obbligatorio',
    refuseAll: 'Rifiuta tutto',
    acceptAll: 'Accetta tutto',
    saveAndClose: 'Salva e chiudi',
    confidentiality: 'Privacy',
    makeYourChoice: 'Fate la vostra scelta:',
    noneTitle: 'Privacy',
    noneText:
      "Canal+ Group utilizza gli identificatori di misurazione dell:'audience, necessari per la gestione dei suoi servizi, per misurare le prestazioni dei suoi servizi e per analizzare le sezioni, le pagine e/o i contenuti consultati, in modo totalmente anonimo e aggregato e con l'unico obiettivo di produrre, per suo conto esclusivo, statistiche prive di dati personali. L'utente può opporsi all'uso di questi identificatori di misura anonimi e disattivarli di seguito.",
  },
  HDPicto: {
    Icon: {
      hd: {
        description: 'HD',
      },
      uhd: {
        description: 'UHD',
      },
    },
  },
  HorizontalPaging: {
    next: 'Successivo',
    previous: 'Precedente',
  },
  Icon: {
    check: 'Confermato',
    close: 'Chiudi',
    back: 'Indietro',
    playlistOn: 'Aggiungi alla tua playlist',
    playlistOff: 'Rimuovi dalla tua playlist',
    download: 'Scarica',
    live: 'In diretta',
    loading: 'Caricamento in corso',
    play: 'In riproduzione',
  },
  List: {
    selected: 'selezionato',
  },
  LiveGrid: {
    ariaLabel: 'Canali live',
    cards: {
      genericTitle: 'Informazioni non disponibili',
      genericSubtitle: 'Altro',
    },
    favorites: {
      emptyStateHeading: 'Nessun canale preferito',
      emptyStateText: 'Seleziona i canali che ti piacciono di più per ritrovarli facilmente nei "Preferiti"',
      editButton: 'Gestisci i preferiti',
      editButtonEmptyState: 'Aggiungi',
    },
    editFavorites: {
      heading: 'Seleziona i tuoi canali preferiti',
      headingMobile: 'Canali preferiti',
      saveButton: 'Conferma',
      loadErrorMessage: 'Ops, si è verificato un errore',
      loadErrorButton: 'Ricomincia',
      saveErrorMessage: 'Abbiamo riscontrato un errore durante il salvataggio. Ti invitiamo a riprovare più tardi.',
      closeErrorButton: 'Chiudi',
    },
    multiLive: {
      cardMultiLiveCallToAction: 'Avvia la modalità Multi Live',
      buttonLaunchMultiLive: 'Attiva il mosaico',
      fetchErrorMessage: 'Ops, si è verificato un errore',
      fetchErrorButton: 'Ricomincia',
    },
  },
  LiveTV: {
    allChannels: 'Tutti i canali',
  },
  LogoAvailabilities: {
    text: 'Disponibile',
  },
  MediaCardBody: {
    broadcastInfoLabel: 'Trasmesso %{date} su %{channelName}',
    onGoingBroadcastInfoLabel: 'Attualmente in onda su %{channelName}',
  },
  MediaCardHeader: {
    error: 'Si è verificato un errore. Ti invitiamo a provare più tardi.',
    prospect: 'Vuoi guardare',
    broadcastingDate: 'Trasmesso',
    noMoreDiffusion: 'Questo contenuto non è più trasmesso',
    unavailableContent: 'Questo contenuto non è disponibile',
    inAppMessageApple: "Per guardare questo programma, modifica la tua offerta dall'App Store di Apple",
    inAppMessageGoogle: 'Per guardare questo programma, modifica la tua offerta dal Play Store di Google',
    scrollerEpisodeText: 'Guarda gli episodi',
  },
  MetaTags: {
    title: ' | guarda in streaming e in replica (VI, VOST e HD) su CANAL+',
    tvodTitle: ' in VOD e offline su',
  },
  Mood: {
    disclaimer: 'Esprimi la tua opinione',
    dislike: 'Non mi piace',
    neutral: 'Così così',
    like: 'Mi piace',
    noopinion: 'Opinione',
  },
  MoodButton: {
    notAvailableRecoOff:
      'Per accedere a questa funzione, è necessario attivare i consigli e la personalizzazione dal menù Impostazioni',
  },
  Sharing: {
    title: 'Condividi',
  },
  MyAccountTemplate: {
    accessAccount: 'Accedi alla mia area profilo',
    cancel: 'Disdici',
    modifyEmail: 'Modifica la mia email',
    modifyLabel: 'Modifica',
    modifyPwd: 'Modifica la mia password',
    myAccount: 'Il mio account',
    myCanalAccount: 'Account TIMVISION',
    name: 'Nome',
    offer: "Scopri l'offerta TIMVISION più adatta a te!",
    personalInformation: 'Le mie info',
    reactivateRightsInfo: 'Se non vedi i contenuti inclusi nella tua offerta clicca su Aggiorna',
    subscription: 'Il mio abbonamento',
    subscriptionLabel: 'Non vedi i contenuti inclusi nella tua offerta?',
    updateRightsAction: 'Aggiorna',
    updateRightsLabel: 'Aggiorna diritti di visione',
  },
  Navigation: {
    dropdownLabel: 'Maggiori informazioni',
  },
  OfflineTemplate: {
    title: 'Problema di connessione',
    offlineDescription: "Sei attualmente offline, effettua l'accesso per visualizzare questa pagina.",
    onlineDescription: 'Sembra che tu sia tornato online, fai clic sul pulsante per ricaricare.',
    retry: 'Riprova',
    reload: 'Ricarica',
  },
  PartnerSelection: {
    backIconTitle: 'Icona per tornare indietro',
  },
  PasswordInput: {
    numberCharacter: 'Minimo 8 caratteri',
    lowerCaseUpperCaseCharacter: 'Almeno una maiuscola, una minuscola e un numero',
    noMandatoryCharacter: "Non contiene i seguenti caratteri: ‹ › ' “ ",
  },
  PlaylistButton: {
    added: 'Aggiunto alla tua playlist',
    alreadyAdded: 'Già aggiunta alla playlist',
    buttonLabel: 'Playlist',
    removed: 'Rimosso dalla tua playlist',
    error: 'Tentativo fallito, prova di nuovo',
    notAvailableRecoOff:
      'Per accedere a questa funzione, è necessario attivare i consigli e la personalizzazione dal menù Impostazioni',
  },
  ProfileManager: {
    addProfile: 'Aggiungi un sottoprofilo',
    add: 'Aggiungi',
    avatarPrefix: 'Avatar',
    chooseAvatar: 'Scegliere un avatar',
    closeModal: 'Chiudi',
    deleteProfile: 'Elimina questo profilo',
    deleteProfileCancelButton: 'Annulla',
    deleteProfileConfirmButton: 'Ok',
    deleteProfileConfirmText: 'Vuoi veramente cancellare il profilo %{displayName} ?',
    deleteProfileConfirmTitle: 'Conferma cancellazione',
    deleteToCreate: 'È necessario eliminare un profilo per crearne uno nuovo.',
    edit: 'Modifica il profilo',
    editAriaLabel: 'Modifica il profilo %{displayName}',
    editAvatarAriaLabel: 'Modifica il avatar',
    inputPlaceholder: 'Nome',
    kidsProfileSubText:
      "Questo è un profilo per bambini che consente l'accesso diretto a uno spazio personalizzato, sicuro e su misura per i tuoi bambini.",
    kidsProfileSettings: 'Profilo Kids',
    kidsProfileTitle: 'Profilo Kids',
    manage: 'Gestisci i profili',
    maxAmount: 'Hai raggiunto il numero massimo di profili su questo account.',
    newWindowAriaLabel: '(nuova finestra)',
    profileIconAriaLabel: 'Menu, account e login',
    submitCreation: 'Conferma',
    submitEdition: 'Salva le modifiche',
    title: 'Cambia il profilo',
    userReachedMaxNumberProfilesTitle: 'Numero massimo di profili',
    userReachedMaxNumberProfilesText:
      'Hai raggiunto il numero massimo di 10 profili su questo account. Sarà necessario eliminare un profilo per crearne uno nuovo.',
  },
  ProspectLoginMessage: {
    message: 'Oltre 200 canali e TNT in diretta e in differita.',
    commitment: 'Senza impegno',
    offer: 'Scopri le nostre offerte',
  },
  SafetyDisclaimer: {
    error: 'Si è verificato un errore del server. Ti invitiamo a riprovare più tardi.',
    wrongCode: 'PIN non corretto. Si prega di riprovare',
    close: 'Chiudi',
    [SafetyCodeContext.Parental]: {
      title: 'Contenuti protetti da PIN',
      tooltip: "Se hai dimenticato il PIN accedi all'area profilo e fai il reset",
      forgotten: 'PIN di Parental Control dimenticato?',
      safetyCodeTitle: 'PIN di Parental Control',
      safetyCodeInstruction: 'Inserisci le 4 cifre del tuo PIN di Parental Control',
      resetSafetyCode: 'Fai il reset del tuo PIN di Parental Control',
      forgottenTitle: 'PIN dimenticato',
      forgottenText: 'Per cambiare/modificare il tuo PIN di Parental Control accedi alla tua area profilo.',
      lockedTitle: 'Blocco dell’inserimento del codice genitore',
      lockedText:
        'In seguto ad un eleveato numero di tentativi errati, l’accesso ai contenuti protetti da PIN di Parental Control è bloccato per 2 ore. Riprova più tardi o effettua il reset del tuo PIN di Parental Control.',
      createTitle: 'Gestisci il PIN di Parental Control',
      createText:
        "Il PIN di Parental Control protegge l'accesso ai contenuti sensibili. Per gestire il PIN di Parental Control collegati a ",
      subCreateText: 'oppure inquadra il QR Code con il tuo smartphone.',
      inOffer: 'La creazione di un codice parentale è necessaria per accedere a questo programma.',
      notInOffer:
        "L'accesso ai programmi per adulti non è disponibile, devi attivarlo nel tuo abbonamento o modificare l'offerta.",
      createCode: 'Crea il tuo PIN di Parental Control',
      enterCode: 'Inserisci il tuo PIN di Parental Control',
      conditions: 'Inserendo il PIN confermi di essere maggiorenne.',
      warningDescription:
        'I contenuti di questa sezione sono destinati solo ad un pubblico adulto in quanto i temi trattati o le immagini presenti possono nuocere allo sviluppo fisico, mentale o morale dei minori. A tutela dei minori, per accedere alla sezione, è richiesto l’inserimento del codice PIN che puoi configurare all’interno del tuo profilo.',
    },
    [SafetyCodeContext.Purchase]: {
      title: 'Contenuti protetti da PIN',
      tooltip: "Se hai dimenticato il PIN accedi all'area profilo e fai il reset",
      forgotten: 'Hai dimenticato il PIN di acquisto?',
      safetyCodeTitle: 'PIN di acquisto',
      safetyCodeInstruction: 'Inserisci il PIN di acquisto a 4 cifre',
      resetSafetyCode: 'Reimposta il PIN di acquisto',
      forgottenTitle: 'PIN dimenticato',
      forgottenText: 'Per cambiare/modificare il tuo PIN di Parental Control accedi alla tua area profilo.',
      lockedTitle: 'Pin di acquisto bloccato',
      lockedText: 'Hai inserito più volte un PIN errato. Riprova tra 2 ore oppure reimpostalo.',
      createTitle: 'Gestisci il PIN di acquisto',
      createText:
        'II PIN di acquisto consente di proteggere gli acquisti. Puoi attivarlo o modificarlo in qualsiasi momento su',
      subCreateText: 'oppure inquadra con il tuo smartphone il QR CODE.',
      needPurchaseCode:
        'Per evitare acquisti accidentali, create il vostro codice di acquisto. È possibile gestirla in qualsiasi momento dalla sezione Il mio account.',
      createCode: 'Creare il codice di acquisto',
      enterCode: 'Inserire il codice di acquisto',
      conditions: 'Inserendo il PIN confermi di essere maggiorenne.',
      warningDescription: '',
    },
  },
  Search: {
    searchInputLabel: 'Ricerca',
    activateSearchTriggerLabel: 'Ricerca',
    closeSearchTriggerLabel: 'Chiudi la ricerca',
    noResultTitle: 'Nessun risultato',
  },
  ServerError: {
    title: 'Abbiamo riscontrato un problema!',
    text: 'Stiamo facendo del nostro meglio per riparare il servizio ed eliminare tutti gli alieni.',
  },
  SettingsTemplate: {
    settings: 'Preferenze',
    recommandation: 'Consigli e personalizzazione',
    pushMood: 'Notifica a fine riproduzione',
    pushMoodExplanation:
      'La notifica a fine riproduzione ti permette di esprimere la tua opinione al termine della visione di un contenuto in modo da alimentare il motore dei consigli personalizzati di TIMVISION',
    episodeAutoplay: 'Riproduzione automatica episodi',
    episodeAutoplayExplanation: 'Gli episodi delle serie vengono collegati automaticamente',
    autoplay: 'Riproduzione automatica',
    animations: 'Anteprime video',
    animationsAutoplayExplanation: 'Riproduzione automatica durante la navigazione',
    animationsSoundExplanation: "Consentire l'audio durante la riproduzione automatica",
    trailer: 'Trailer',
    trailerAutoplayExplanation: 'Riproduzione automatica del trailer',
    trailerSoundExplanation: "Autorizzare l'audio durante la riproduzione automatica",
    personalizedExp: 'TIMVISION personalizza la tua esperienza in base al tuo utilizzo:',
    list: {
      item1: 'riprendere la riproduzione di un programma dopo averlo interrotto, anche su un altro dispositivo',
      item2: 'contenuti consigliati personalizzati',
      item3: 'stato di riproduzione su ogni episodio delle serie',
      item4: 'gestione della tua playlist',
      item5: 'gestione della cronologia di visione',
      item6: 'valutazioni "mi piace"/"non mi piace"',
    },
    explanation:
      'Se non desideri che GROUPE CANAL+ ricorra ai tuoi dati di utilizzo a tali scopi, hai la possibilità di opporti. Tuttavia non potrai più usufruire di tutte le funzionalità di personalizzazione e dei consigli.',
    confirm: 'Conferma le modifiche',
    errorServer: 'Si è verificato un errore del server. Ti invitiamo a riprovare più tardi.',
    imageQuality: {
      title: 'Qualità delle immagini',
      low: 'Bassa',
      medium: 'Media',
      high: 'Alta',
    },
    theme: {
      title: 'Tema',
      system: 'Predefinito da sistema',
      light: 'Scuro',
      dark: 'Chiaro',
    },
    settingsD2gQuality: {
      title: 'Download',
      medium: 'Caricamento rapido',
      high: 'Qualità elevata',
    },
    HDR: {
      title: 'HDR su alcuni canali',
      description:
        'L’HDR migliora la qualità dell’immagine offrendo migliori contrasti nelle zone scure e luminose. Tuttavia, solo alcune TV e alcuni canali sono compatibili. Puoi regolare manualmente l’attivazione dell’HDR se i colori sembrano degradati sui canali HDR o se incontri uno schermo nero.',
      auto: 'Auto',
    },
    realTimeStreaming: {
      title: 'Streaming in tempo reale',
      description: `Lo streaming in tempo reale riduce notevolmente il tempo tra l'acquisizione televisiva e la visualizzazione sul tuo dispositivo, per avvicinarti il più possibile al live. La funzionalità è accessibile solo da alcuni canali
      LIVE. Attenzione: questa impostazione può generare dei periodi di ricarica del video, a seconda della tua connessione. Questa impostazione verrà progressivamente migliorata e distribuita ad altri canali.
      `,
    },
    videoSettings: 'Impostazioni video',
    preferences: 'Preferenze',
    recommandationConfirm: {
      title: 'Disattivare la raccomandazione e la personalizzazione?',
      description:
        'Il profilo di raccomandazione, la cronologia delle visualizzazioni, le opinioni e le playlist andranno persi e le funzioni di personalizzazione saranno disattivate su tutti i dispositivi entro 24 ore.',
      cancel: 'Annulla',
      confirm: 'Conferma',
    },
    whoIsWatching: {
      title: "Profili all'avvio",
      description: "Questa impostazione visualizza l'elenco dei profili all'avvio dell'applicazione.",
    },
    enabled: 'Attivato',
    disabled: 'Spento',
  },
  ShowcaseTemplate: {
    OfferHighlight: {
      pauseBtn: 'Riprodurre il trailer del programma (suono spento)',
      playBtn: 'Mettere in pausa il trailer del programma (suono spento)',
    },
  },
  Slideshow: { skip: 'Chiudi' },
  Sport: {
    now: 'Ora',
    lessThanOneHourAgo: '%{smart_count} minuto fa |||| %{smart_count} minuti fa',
    betweenOneAndTwoHoursAgo: '1 ora %{smart_count} minuti fa',
    someHoursAgo: '%{smart_count} ora fa |||| %{smart_count} ore fa',
    someDaysAgo: '%{smart_count} giorno fa |||| %{smart_count} giorni fa',
  },
  StartOver: {
    seeLive: 'Accedi alla diretta',
    seeFromBeginning: "Guarda dall'inizio",
  },
  StoreRedirection: {
    title: "Approfitta della migliore esperienza video scaricando l'app su dispositivi mobili e tablet",
    buttonText: "Scarica l'app",
  },
  Streaming: {
    realtime: 'Streaming in tempo reale',
    explanation:
      "Lo streaming in tempo reale riduce notevolmente il tempo tra l'acquisizione televisiva e la visualizzazione sul tuo dispositivo, per avvicinarti il più possibile al live. La funzionalità è accessibile solo da alcuni canali LIVE (vedi lista canali sull'Assistenza CANAL+).<br/>Attenzione: questa impostazione può generare dei periodi di ricarica del video a seconda della tua connessione. Questa impostazione verrà gradualmente migliorata e distribuita ad altri canali.",
    whoWatching: 'Chi sta guardando?',
    dontShow: 'Non visualizzare più questa schermata?',
    showOnLaunch: 'Mostrare questa schermata ad ogni avvio?',
    editInSettings: 'E’ possibile modificare l’impostazione «profili all’avvio» alla sezione «preferenze».',
    editInMore: 'È possibile modificare questa preferenza alla voce "Ulteriori informazioni" dell\'applicazione.',
    options: 'Sì / No',
  },
  Video: {
    fullscreen: 'Vedi schermo intero',
    watch: 'Guarda il video',
    play: 'Riprodurre il video',
    pause: 'Mettere in pausa il video',
    mute: 'Disattivare il suono della video',
    unmute: 'Attivare il suono della video',
  },
  TvGuide: {
    search: {
      placeholder: 'Accesso rapido: inserisci il nome di un canale',
      zapNumber: 'Canale',
    },
  },
  ParentalCode: {
    title: 'Parental control',
  },
  PurchaseCode: {
    title: 'Acquisti control',
  },
  PurchaseCodeDeletion: {
    title: 'Disattivazione',
  },
  UserExitsSettingsModal: {
    title: 'Le tue impostazioni sono cambiate',
    question: 'Vuoi confermare le modifiche?',
    confirm: 'Conferma le modifiche',
    cancel: 'Annulla le modifiche',
  },
  UserSettingsModal: {
    title: 'Sei sicuro di voler disattivare i consigli e la personalizzazione?',
    infos:
      'Il tuo profilo dei consigli, la cronologia di visione, le valutazioni date e le playlist andranno perse e le funzioni di personalizzazione saranno disattivate.',
    btn: 'Ho capito',
  },
  ViolentContent: {
    title: 'Gestione della protezione dei contenuti inappropriati per i minori',
  },
  VoucherFeedback: {
    success: 'Codice promozionale applicato',
    invalid: 'Questo codice promozionale non è valido',
  },
  VoucherInput: {
    promotionalCode: 'Codice promozionale',
  },
  WhoIsWatching: {
    title: 'Chi sta guardando?',
    toggleNotDisplay: 'Non visualizzare più questa schermata?',
    toggleNotDisplayDesc: "È possibile modificare questa preferenza alla sezione 'Impostazioni' dell'applicazione.",
  },
  Zone: {
    title: 'Seleziona la tua area geografica',
    subtitle_france: 'Francia e oltremare',
    subtitle_europe: 'Europa',
    subtitle_africa: 'Afrique',
    subtitle_others: 'Altre',
  },
};

export default LOCALE_IT_IT;
